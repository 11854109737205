<style lang="scss">
@include darkOnly() {
  .visual-search {
    border-bottom-color: $dark-blue-alt !important;

    .visual-search_stations img {
      box-shadow: -5px -5px 10px #000;
    }

    .visual-search__search {
      background-color: $dark-blue-alt !important;

      input, label {
        color: white !important;
      }

      input {
        &::placeholder {
          color: white !important;
        }
      }

      .v-icon {
        color: white !important;
      }
    }
  }
}

.visual-search {

  .visual-stations-wrapper {
    border-radius: 2em;
  }

  .visual-search_stations {
    .visual-search-img {
      max-width: 150px;

      img {
        border-radius: 20px;
        background: #e0e0e0;
        box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff;
        transition: box-shadow 0.1s ease;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  &__genre {
    font-size: 1.5em;
  }

  .visual-search__search {
    height: 55px;
    border-radius: 1em;

    .v-input__slot { // remove vuetify styling
      background: none !important;

      &::after, &::before {
        content: none !important;
      }

      .v-icon {
        color: $purple-dark;
        font-size: 2em;
      }
    }

    input, label {
      font-size: 20pt !important;
      font-weight: bold;
      text-indent: 10px;
      color: black !important;
    }

    input {
      &::placeholder {
        color: black !important;
        opacity: 1; /* Firefox */
        font-weight: bold;
      }
    }
  }

  .v-data-footer {
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 15px;

    .v-icon {
      font-size: 30pt;
    }

    .v-data-footer__select {
      display: none; // items per page
    }
  }
}
</style>

<template>
  <section class="visual-search theme-purple-background">
    <div class="px-6 py-10">
      <v-data-iterator
          :items="stations"
          :search="filter"
          :items-per-page.sync="perPage"
          :page.sync="page"
          dark
      >
        <template v-slot:header>
          <v-toolbar
              v-if="!isParcVersion"
              color="white"
              class="mb-1 white--text visual-search__search"
          >
            <v-text-field
                v-model="filter"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search for a station"
            ></v-text-field>
          </v-toolbar>

          <section v-if="!isParcVersion" class="visual-search__genre d-flex justify-center align-center flex-wrap">
            <p :class="$mq === 'sm' ? 'py-1' : 'py-4'"
               class="white--text pa-2 mb-0 text-capitalize font-weight-bold pointer"
               @click="filter = genre"
               v-for="genre in genres" :key="genre">{{ genre }}</p>
          </section>
        </template>
        <template v-slot:default="props">
          <div class="visual-stations-wrapper theme-white-grey px-4">
            <div class="visual-search_stations row pa-2">
              <section :class="$mq === 'sm' ? 'col-4' : 'col-2'"
                       class="col-2 d-flex justify-center align-center animate__animated animate__fadeIn animate__slower" v-for="(item, index) in props.items" :key="item.name + index">
                <div class="visual-search-img pointer" @click="playStation(item)">
                  <img width="100%" height="auto" :src="getImgUrl(item.image_url)">
                </div>
              </section>
            </div>
          </div>

        </template>
        <template v-slot:no-results>
          <p class="white--text font-weight-bold">No matching stations found</p>
        </template>
      </v-data-iterator>
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex';
import radioService from '@/services/radioService';

export default {
  props: [''],
  components: {},
  data() {
    return {
      genres: ['rock', 'jazz', 'pop', 'country', 'urban', 'dance', 'chill', 'classical', 'mixed', 'soul', 'news'],
      filter: '',
      perPage: 18,
      page: 1,
    };
  },
  computed: {
    ...mapState({
      stations: state => state.radio.stations,
    }),
  },
  mounted() {
    this.sortStations();
  },
  methods: {
    sortStations() {
      this.stations.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    },
    playStation(station) {
      this.$emit('play', station);
    },
    getImgUrl(url) {
      return radioService.getImgUrl(url);
    },
  },
};
</script>
