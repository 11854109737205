<style lang="scss">
.app-header {
  width: 100vw;

  .close-modal {
    .v--modal-box {
      padding: 25px;
      background-color: $purple;
      border-radius: 2em;
      text-align: center;

      h1, h5 {
        color: white;
      }
    }

    .v--modal-background-click {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .btn-quit {
    letter-spacing: 1.2px !important;
  }

  .favourites-icon {
    border-radius: 15px;
    width: 30px;
    height: 30px;
    background-color: $purple;

    i {
      color: white;
    }
  }

  .snooze-icon {
    position: relative;
    transform: translateY(7px);

    span {
      display: inline-block;

      &:nth-child(2) {
        transform: translateY(-10px);
      }

      &:nth-child(3) {
        transform: translateY(-20px);
      }
    }
  }

  .dark-mode-switch {
    i {
      color: black;
    }

    &.dark i {
      color: white;
    }

    .v-messages {
      display: none;
    }

    .v-input__slot {
      margin-bottom: 0;
    }
  }
}
</style>

<template>
  <v-app-bar class="app-toolbar app-header theme-background" fixed dense height="64px" max-height="64px">
    <img width="60px" src="../assets/logo.png" alt="">

    <v-btn v-if="isAndroid" @click="$modal.show('close-modal')" class="ml-6 btn-purple font-weight-bold btn-quit">Quit
    </v-btn>

    <region-select />

    <v-spacer></v-spacer>

    <div class="mr-6 dark-mode-switch font-weight-bold" :class="{'dark': darkMode}">
      <v-switch
          color="white"
          v-model="darkMode"
          inset
          :label="darkMode ? 'Dark' : 'Light'"
      >
        <template slot="label">
          <v-icon v-if="!darkMode">fas fa-sun</v-icon>
          <v-icon v-if="darkMode">fas fa-moon</v-icon>
        </template>
      </v-switch>
    </div>
    <div @click="showOverflow(true)"
         :class="{'disabled': !station.playing || !playing || station.playing._state === 'loading'}"
         class="snooze-icon mr-6 pointer theme-color-alt"><span>z</span><span>z</span><span>z</span></div>
    <span @click="scrollToFavourites()" class="favourites-icon d-flex justify-center align-center"><i
        class="fas fa-heart "></i></span>

    <relax-overflow :class="{'active': showOverlay}" @close="showOverflow(false)"></relax-overflow>

    <modal class="close-modal" name="close-modal">
      <h1 class="mb-8">Are you sure you want to close the radio?</h1>
      <section class="d-flex full-width justify-center">
        <v-btn x-large class="mr-6 btn-green" @click="closeApp()">Yes</v-btn>
        <v-btn x-large class="btn-red" @click="$modal.hide('close-modal')">No</v-btn>
      </section>
    </modal>
  </v-app-bar>
</template>

<script>
import {mapState} from 'vuex';
import {Howler} from 'howler';
import RelaxOverflow from './RelaxOverflow';
import RegionSelect from '@/components/search/RegionSelect.vue';

export default {
  name: 'App-Header',
  props: [''],
  components: {
    RelaxOverflow,
    RegionSelect,
  },
  computed: {
    ...mapState({
      station: state => state.radio.station,
      isAndroid: state => state.isAndroid,
      playing: state => state.radio.playing,
    }),
    darkMode: {
      get() {
        return this.$store.state.darkMode
      },
      set(value) {
        this.$store.commit('SET_DARK_MODE', value)
      }
    },
  },
  data() {
    return {
      showOverlay: false,
      dark: false
    };
  },
  mounted() {
    this.$store.dispatch('setIsAndroid');
  },
  methods: {
    scrollToFavourites() {
      var favourites = document.querySelector('.favourites-section');

      if (favourites) {
        favourites.scrollIntoView();
      }
    },
    showOverflow(value) {
      this.showOverlay = value;
    },
    closeApp() {
      this.sendLog('Quit Radio App');
      Howler.unload(); // stop all music
      // eslint-disable-next-line no-undef
      Android.quit();
    },
    sendLog(description, meta = null) {
      this.$store.dispatch('radio/sendLog', {
        description: description,
        meta: meta
      });
    },
  },
};
</script>
