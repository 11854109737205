<style lang="scss">
  .home{

  }
</style>
<template>
  <div class="home">
    <radio></radio>
  </div>
</template>

<script>
import Radio from '../components/radio.vue';

export default {
  name: 'Home',
  components: {
    Radio,
  },
  mounted() {
    if (this.isParcVersion) {
      const appElement = document.querySelector('.v-application');
      if (appElement) {
        appElement.style.paddingTop = '0';
      }
    }
  }
}
</script>
